/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import TablePercentageBar from '../TablePercentageBar';
import DataTable from 'react-data-table-component';
import { MdUpdate } from 'react-icons/md';
import { useAuth } from '../../hooks/auth';

import { Container, UpdateButton } from './styles';

const Table = ({ data, resultCallback, isEdit = true}) => {
  const collumnData = [
    {
      name: 'Resultado Pactuado',
      selector: 'title',
      minWidth: '0px',
    },
    {
      name: 'Indicador de resultado',
      selector: 'indicator',
      minWidth: '0px',
    },
    {
      name: 'Meta',
      selector: 'goal',
      minWidth: '0px',
    },
    {
      name: 'Status de Execução',
      selector: 'execution_physic',
      minWidth: '0px',
      cell: row => (
        <TablePercentageBar percentage={row.execution_physic} color="yellow" />
      ),
    },
    {
      name: 'Projetos Relacionados',
      selector: 'project',
      minWidth: '0px',
      cell: row => <div>{!!row.project ? row.project : '-'}</div>,
    },
    {
      name: 'Última Atualização',
      selector: 'updated_at',
      minWidth: '0px',
      cell: row => (
        <div>{`${row.updated_at.split('-')[2]}/${
          row.updated_at.split('-')[1]
        }/${row.updated_at.split('-')[0]}`}</div>
      ),
    },
  ]
  const [ columns, setColumns ] = React.useState([])

  React.useEffect(() => {
    let arr = collumnData
    if (isEdit) {
      arr.push({
        name: 'Editar',
        selector: 'editar',
        minWidth: '0px',
        cell: row => (
          <UpdateButton onClick={() => resultCallback(row)}>
            <MdUpdate color="#fff" />
          </UpdateButton>
        ),
      })
    }
    
    setColumns(arr)
  }, [isEdit, resultCallback])
  
  return (
    <Container>
      <DataTable columns={columns} data={data} noHeader noDataComponent="" />
    </Container>
  );
};

export default Table;
