import React from 'react';
import { MdSearch } from 'react-icons/md';
import { Container, Card } from './styles';

function IndicatorCard({ indicator, initDetailIndicator, showButton }) {
  return (
    <Container showButton={showButton}>
      <div className="textContainer">
        <div className="text">
          {indicator.indicator.short_name}
          <p>{`(${indicator.indicator.source})`}</p>
        </div>
        {showButton && (
          <button type="button" onClick={() => initDetailIndicator(indicator)}>
            <MdSearch color="#fff" />
          </button>
        )}
      </div>
      <div className="bottomContainer">
        <div className="iconsContainer">
          {indicator.icons.map((icon, index) => (
            
              <Card className={icon.border} key={`ci-${index}`}>
                <div className="imgContainer">
                  <img
                    className={icon.border}
                    src={`/static/media/${icon.icon_name}`}
                    alt=""
                  />
                </div>
              </Card>
            
          ))}
        </div>
        <div className="periodContainer">
          <p>Periodo:</p>
          <p>{indicator.indicator.period}</p>
        </div>
      </div>
    </Container>
  );
}

export default IndicatorCard;
