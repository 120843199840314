/* eslint-disable */
import React, { useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { FaEnvelope, FaSignInAlt, FaCheckCircle } from 'react-icons/fa';
import Modal from '../Modal';
import { useAuth } from '../../hooks/auth';
import { Form } from '@unform/web';
import Input from '../Input';
import ModalLoader from '../ModalLoader';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import Logo from '../../assets/logo_plataforma_novo.png';
import Bid from '../../assets/logo-bid-no-background.png';
import { Container } from './styles';

const schema = Yup.object().shape({
  mail: Yup.string()
    .email('digite um email válido')
    .required('email é obrigatório'),
});

function ForgotModal({ show, handleClose }) {
  const formRef = useRef(null);
  const { changeForgotModal, changeLoginModal } = useAuth();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const openLoginModal = useCallback(() => {
    changeLoginModal(true);
    changeForgotModal(false);
  }, [changeForgotModal, changeLoginModal]);

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current?.setErrors({});
      setError('');

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      await api.post('/accounts/reset-password/', data);

      setLoading(false);
      setSuccessMessage(true);
    } catch (err) {
      const { ...error } = err;
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setLoading(false);
      setError(
        'Ocorreu um erro ao tentar resetar a senha. Tente novamente ou entre em contato com o suporte.',
      );
      console.error('err', error);
    }
  }, []);

  return (
    <Container>
      <Modal
        title="Login"
        success={false}
        successText=""
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <div className="loginContainer">
            {successMessage ? (
              <div className="formContainer">
                <h1 className="successTitle">
                  Reset de senha solicitado com sucesso
                </h1>
                <small>
                  Enviamos um link de confirmação para o seu e-mail.
                </small>
                <FaCheckCircle size={100} color="#00a9ee" />
              </div>
            ) : (
              <div className="formContainer">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <h1>Esqueci minha senha</h1>
                  <small>Digite o email usado na hora do cadastro:</small>
                  <Input
                    id="email"
                    name="mail"
                    icon={FaEnvelope}
                    className="input"
                    placeholder="Email"
                  />
                  {error && <div className="errormessage">{error}</div>}
                  <div className="submitContainer">
                    <button type="submit" className="submit">
                      <FaSignInAlt />
                      Enviar
                    </button>
                  </div>
                </Form>

                <div className="register">
                  <button
                    type="button"
                    className="forgotButton"
                    onClick={openLoginModal}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            )}

            <div className="infoContainer">
              <div className="logo1">
                <img src={Logo} alt="" />
              </div>
              <div className="text">
                <span>Acesso às linhas de financiamento</span>
              </div>
              <div className="logo2">
                <img src={Bid} alt="" />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Container>
  );
}

export default ForgotModal;
