/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../services/api';
import * as Yup from 'yup';
import jsPDF from 'jspdf';
import { formatPrice } from '../../services/currencyFormatter';
import html2canvas from 'html2canvas';
import { Form } from '@unform/web';
import DataTable from 'react-data-table-component';
import getValidationErrors from '../../services/getValidationErrors';
import DashboardLayout from '../../components/DashboardLayout';
import Solution from '../../components/Solution';
import PdfHeader from '../../assets/pdfheader.png';
import Loader from '../../components/Loader';
import TextArea from '../../components/TextArea';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import FileInput from '../../components/FileInput';
import ImpactAccordeon from '../../components/ImpactAccordion';
import SuccessModal from '../../components/SuccessModal';
import TitleInfo from '../../components/TitleInfo';
import { FaPlus } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Row, Column } from '../../styles/components';

import {
  Container,
  BackButton,
  Title,
  Card,
  SubTitle,
  FormSectionContainer,
  DeleteButton,
  PrintableBodyWrapper,
  PrintableDiv,
  FormButton,
  ImpactError,
  SolutionsContainer,
  TotalContainer,
} from './styles';
import { useAuth } from '../../hooks/auth';

const FinalizePreProposal = () => {
  const formRef = useRef(null);
  const printRef = useRef(null);
  const [total, setTotal] = useState(0);
  const [impactError, setImpactError] = useState('');
  const [loading, setLoading] = useState(true);
  const [preProposal, setPreProposal] = useState({
    user: {},
    administration: [],
  });
  const [impacts, setImpacts] = useState([]);
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [involvedSecretaries, setInvolvedSecretaries] = useState([]);
  const [territorialFocus, setTerritorialFocus] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [printing, setPrinting] = useState(false);


  const { user, signOut } = useAuth();

  const schema = Yup.object().shape({
    general_goals: Yup.string().required('Objetivo Geral é obrigatório'),
    secretary_title: Yup.string().required('Nome da secretaria é obrigatória'),
    secretary_name: Yup.string().required(
      'Nome do(a) secretario(a) é obrigatório',
    ),
    phone: Yup.string().required('Telefone é Obrigatório'),
    email: Yup.string()
      .required('O Email é obrigatório')
      .email('Digite um Email válido'),
    governance_detail: Yup.string().required(
      'Detalhe  da Governança é obrigatório',
    ),
    strategic_alignments: Yup.string().required(
      'Alinhamento Estratégico é obrigatório',
    ),
    internal_regiment_file: Yup.string().required('este Arquivo é obrigatório'),
    organogram_file: Yup.string().required('este Arquivo é obrigatório'),
    planning_instrument: Yup.string().required('este Arquivo é obrigatório'),
    desired_impacts: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required(),
          indicators: Yup.array(),
          name: Yup.string().max(255).required(),
        }),
      )
      .min(1, 'Adicione pelo menos um indicador de impacto'),
  });

  const JsonParser = useCallback(val => {
    try {
      return JSON.parse(val);
    } catch (err) {
      return [];
    }
  }, []);

  const getPreProposal = useCallback(async () => {
    try {
      const ApiUrl = '/platform/v1/pre-proposal/my-proposal/';
      const response = await api.get(ApiUrl);
      const ParsedAdministration =
        typeof response.data.administration === 'string'
          ? JsonParser(response.data.administration)
          : response.data.administration;

      setPreProposal({
        ...response.data,
        administration: ParsedAdministration,
      });

      formRef?.current?.setFieldValue(
        'organogram_file',
        response.data.organogram_file,
      );
      formRef.current?.setFieldValue(
        'planning_instrument',
        response.data.planning_instrument,
      );
      formRef.current?.setFieldValue(
        'internal_regiment_file',
        response.data.internal_regiment_file,
      );
      const parsedSecretaries =
        typeof response.data.administration === 'string'
          ? JsonParser(response.data.involved_secretaries)
          : response.data.involved_secretaries;

      const parsedSelectedImpacts =
        typeof response.data.administration === 'string'
          ? JsonParser(response.data.desired_impacts)
          : response.data.desired_impacts;

      const parsedTerritorialFocus =
        typeof response.data.administration === 'string'
          ? JsonParser(response.data.territorial_focus)
          : response.data.territorial_focus;

      setInvolvedSecretaries(parsedSecretaries);
      setTerritorialFocus(parsedTerritorialFocus);
      setSelectedImpacts(parsedSelectedImpacts);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, []);

  const getImpacts = useCallback(async () => {
    try {
      const ApiUrl = '/platform/v1/pre-proposal/my-proposal/impacts/';
      const response = await api.get(ApiUrl);
      setImpacts(response.data.results);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, []);

  const getFile = async url => {
    let response = await fetch(url);
    let data = await response.blob();

    const stringArr = url.split('.');
    const extension = stringArr[stringArr.length - 1];

    const getMimeType = mime => {
      const types = {
        jpg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        ppt: 'application/vnd.ms-powerpoint',
        svg: 'image/svg+xml',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      };
      return types[mime];
    };

    return new File([data], `file.${extension}`, {
      type: getMimeType(extension),
    });
  };

  const handleSelectImpact = useCallback(
    data => {
      const exists = selectedImpacts.filter(
        impact => impact.code === data.code,
      );

      if (exists.length && data.indicators.length) {
        const removeIndex = selectedImpacts
          .map(imp => imp.code)
          .indexOf(data.code);
        selectedImpacts.splice(removeIndex, 1);
        setSelectedImpacts([...selectedImpacts, data]);
      } else if (exists.length && !data.indicators.length) {
        const removeIndex = selectedImpacts
          .map(imp => imp.code)
          .indexOf(data.code);
        selectedImpacts.splice(removeIndex, 1);
        setSelectedImpacts([...selectedImpacts]);
      } else {
        setSelectedImpacts([...selectedImpacts, data]);
      }
    },
    [selectedImpacts],
  );

  const handleSave = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        if (
          !!preProposal.internal_regiment_file &&
          !!preProposal.organogram_file &&
          !!preProposal.planning_instrument
        ) {
          data = {
            ...data,
            desired_impacts: selectedImpacts,
            internal_regiment_file:
              typeof preProposal.internal_regiment_file === 'string'
                ? preProposal.internal_regiment_file
                : '',
            organogram_file:
              typeof preProposal.organogram_file === 'string'
                ? preProposal.organogram_file
                : '',
            planning_instrument:
              typeof preProposal.planning_instrument === 'string'
                ? preProposal.planning_instrument
                : '',
          };
        }
        await schema.validate(data, { abortEarly: false });

        const {
          secretary_name_input,
          population,
          location,
          secretary_title,
          secretary_name,
          phone,
          email,
          ...transformedData
        } = data;

        const organogram_file_ =
          typeof preProposal.organogram_file === 'string'
            ? await getFile(preProposal.organogram_file)
            : transformedData.organogram_file;

        const planning_instrument_ =
          typeof preProposal.planning_instrument === 'string'
            ? await getFile(preProposal.planning_instrument)
            : transformedData.planning_instrument;

        const internal_regiment_file_ =
          typeof preProposal.internal_regiment_file === 'string'
            ? await getFile(preProposal.internal_regiment_file)
            : transformedData.internal_regiment_file;

        const validationData = {
          ...transformedData,
          administration: JSON.stringify([
            {
              secretary_title: data.secretary_title,
              secretary_name: data.secretary_name,
              phone: data.phone,
              email: data.email,
            },
          ]),
          involved_secretaries: JSON.stringify(involvedSecretaries),
          desired_impacts: JSON.stringify(selectedImpacts),
          territorial_focus: JSON.stringify(territorialFocus),
          organogram_file: organogram_file_,
          planning_instrument: planning_instrument_,
          internal_regiment_file: internal_regiment_file_,
        };

        const formData = new FormData();
        for (const name in validationData) {
          if (Object.prototype.hasOwnProperty.call(validationData, name)) {
            formData.append(name, validationData[name]);
          }
        }

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        setLoadingSuccess(true);
        setShowSuccess(true);

        validationData.organogram_file === undefined &&
        validationData.planning_instrument === undefined &&
        validationData.internal_regiment_file === undefined
          ? await api.put(
              '/platform/v1/pre-proposal/my-proposal/',
              validationData,
            )
          : await api.put(
              '/platform/v1/pre-proposal/my-proposal/',
              formData,
              config,
            );

        printDocument();
      } catch (err) {
        console.error(err);
        setLoadingSuccess(false);
        setShowSuccess(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          if (!!errors.desired_impacts) {
            setImpactError(errors.desired_impacts);
          }
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema],
  );

  const addSecretaries = useCallback(() => {
    const formValues = formRef.current.getData();
    if (formValues['secretary_name_input']) {
      setInvolvedSecretaries([
        ...involvedSecretaries,
        { secretary_name: formValues['secretary_name_input'] },
      ]);
      formRef.current?.setFieldValue('secretary_name_input', '');
    }
  }, [involvedSecretaries]);

  const removeSecretaries = useCallback(
    item => {
      const removeIndex = involvedSecretaries
        .map(sec => sec.secretary_name)
        .indexOf(item.secretary_name);

      involvedSecretaries.splice(removeIndex, 1);
      setInvolvedSecretaries([...involvedSecretaries]);
    },
    [involvedSecretaries],
  );

  const addTerritorialFocus = useCallback(() => {
    const formValues = formRef.current.getData();
    if (formValues['location'] && formValues['population']) {
      setTerritorialFocus([
        ...territorialFocus,
        {
          location: formValues['location'],
          population: formValues['population'],
        },
      ]);
      formRef.current?.setFieldValue('location', '');
      formRef.current?.setFieldValue('population', '');
    }
  }, [territorialFocus]);

  const removeTerritorialFocus = useCallback(
    item => {
      const removeIndex = territorialFocus
        .map(foc => foc.location)
        .indexOf(item.location);
      territorialFocus.splice(removeIndex, 1);
      setTerritorialFocus([...territorialFocus]);
    },
    [territorialFocus],
  );

  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  const printDocument = useCallback(() => {
    window.scrollTo(0, 0);
    setLoadingSuccess(true);
    setShowSuccess(true);
    setPrinting(true);
    setTimeout(() => {
      html2canvas(printRef.current, {
        ignoreElements: function (element) {
          if (element.classList.contains('successModal')) {
            return true;
          }
        },
      }).then(async canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        const base64 = await canvas.toDataURL(PdfHeader);
        doc.addImage(base64, 'PNG', 0, 0, 210, 39);
        doc.addImage(imgData, 'PNG', 10, 42, imgWidth, imgHeight + 25);
        doc.setFillColor(0, 0, 0, 0);
        doc.rect(0, 287, 210, 15, 'F');

        heightLeft -= pageHeight;
        heightLeft = heightLeft + 45;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;

          doc.addPage();
          doc.addImage(
            imgData,
            'PNG',
            10,
            position + 13,
            imgWidth,
            imgHeight + 25,
          );

          doc.setFillColor(0, 0, 0, 0);
          doc.rect(0, 0, 210, 10, 'F');

          doc.setFillColor(0, 0, 0, 0);
          doc.rect(0, 287, 210, 15, 'F');

          heightLeft -= pageHeight;
          heightLeft = heightLeft + 15;
        }
        window.open(doc.output('bloburl'));
        setPrinting(false);
        setLoadingSuccess(false);
      });
    }, 500);
  }, []);

  const Goback = () => {
    window.location.href = '/platform/estimar-investimento/';
  };

  useEffect(() => {
    getPreProposal();
    getImpacts();
  }, []);

  useEffect(() => {
    if (!!Object.keys(preProposal.user || {location: preProposal.location}).length && impacts.length) {
      const SolutionsTotal = preProposal.location_solutions?.reduce(function (
        total,
        solution,
      ) {
        return total + solution.cost_estimate;
      },
      0);
      setTotal(SolutionsTotal);
      setLoading(false);
    }
  }, [preProposal, impacts]);

  const secretariesColumns = [
    {
      name: 'Nome da Secretaria',
      selector: 'secretary_name',
      minWidth: '0px',
    },
    {
      name: `${printing ? '' : 'Remover'}`,
      minWidth: '0px',
      maxWidth: '80px',
      cell: row => (
        <DeleteButton
          onClick={() => removeSecretaries(row)}
          type="button"
          display={printing.toString()}
        >
          <MdDelete size={18} />
        </DeleteButton>
      ),
    },
  ];

  const territorialFocusColumns = [
    {
      name: 'Municipio/Bairro',
      selector: 'location',
      minWidth: '0px',
    },
    {
      name: 'População Estimada',
      selector: 'population',
      minWidth: '0px',
    },
    {
      name: `${printing ? '' : 'Remover'}`,
      minWidth: '0px',
      maxWidth: '80px',
      cell: row => (
        <DeleteButton
          onClick={() => removeTerritorialFocus(row)}
          type="button"
          display={printing.toString()}
        >
          <MdDelete size={18} />
        </DeleteButton>
      ),
    },
  ];

  return (
    <Container>
      <DashboardLayout title="Finalizar Pré Proposta">
        {loading ? (
          <Loader />
        ) : (
          <PrintableBodyWrapper ref={printRef}>
            <BackButton onClick={() => Goback()} display={printing.toString()}>
              Voltar
            </BackButton>
            <Title>Pré-Proposta</Title>
            <SubTitle>Informações do Usuário</SubTitle>
            <Card color="#f8f8f8" shadow>
              {/* {!!preProposal && !!Object.keys(preProposal.user).length && (
                <>
                  <span>Nome:</span>
                  <p>{preProposal.user.full_name}</p>
                  <span>Orgão de atuação no governo/prefeitura:</span>
                  <p>
                    {!!preProposal.user.orgao_atuacao
                      ? `${preProposal.user.orgao_atuacao}`
                      : 'Não Informado'}
                  </p>
                  <span>Cargo :</span>
                  <p>
                    {!!preProposal.user.role && preProposal.user.role !== 'None'
                      ? `${preProposal.user.role}`
                      : 'Não Informado'}
                  </p>
                  <span>E-mail:</span>
                  <p>{preProposal.user.institutional_mail}</p>
                  <span>Telefone:</span>
                  <p>
                    {!!preProposal.user.phone
                      ? `${preProposal.user.phone}`
                      : 'Não Informado'}
                  </p>
                </>
              )}  */}

              {!!preProposal && !!Object.keys(user).length && (
                <>
                  <span>Nome:</span>
                  <p>{user.full_name}</p>
                  <span>Orgão de atuação no governo/prefeitura:</span>
                  <p>
                    {!!user.orgao_atuacao
                      ? `${user.orgao_atuacao}`
                      : 'Não Informado'}
                  </p>
                  <span>Cargo :</span>
                  <p>
                    {!!user.role && user.role !== 'None'
                      ? `${user.role}`
                      : 'Não Informado'}
                  </p>
                  <span>E-mail:</span>
                  <p>{user.institutional_mail}</p>
                  <span>Telefone:</span>
                  <p>
                    {!!user.phone
                      ? `${user.phone}`
                      : 'Não Informado'}
                  </p>
                </>
              )}
            </Card>
            <Form ref={formRef} onSubmit={handleSave}>
              <FormSectionContainer>
                <h1>
                  Objetivo Geral da operação de crédito a ser contratada
                  <TitleInfo info="Nesse campo deve-se inserir um texto (sintético) através do qual seja apresentada a finalidade geral do projeto e os objetivos específicos que deseja atender." />
                </h1>
                <Row>
                  <Column small="12" medium="12" large="12" display={printing.toString()}>
                    <TextArea
                      name="general_goals"
                      placeholder="Descrição do Objetivo"
                      schema={schema}
                      defaultValue={
                        !!preProposal.general_goals
                          ? preProposal.general_goals
                          : ''
                      }
                    />
                  </Column>
                  <PrintableDiv display={printing.toString()}>
                    {formRef.current?.getFieldValue('general_goals')}
                  </PrintableDiv>
                </Row>
              </FormSectionContainer>

              <FormSectionContainer>
                <h1 style={{ marginBottom: '15px' }}>
                  Impactos Desejados
                  <TitleInfo info="Nesta seção o objetivo geral da operação deve ser detalhada em termos dos impactos que se deseja efetivar, mediante a execução da operação de crédito. Esses impactos foram selecionados automaticamente, dada sua vinculação às soluções selecionadas. Para cada impacto você deverá especificar pelo menos um indicador de mensuração." />
                </h1>
                {impacts.map((impact, index) => (
                  <ImpactAccordeon
                    key={`ia-${index}-${impact.code}`}
                    title={impact.name}
                    impact={impact}
                    selected={selectedImpacts}
                    callback={handleSelectImpact}
                    printing={printing.toString()}
                  />
                ))}
                <ImpactError>{impactError}</ImpactError>
              </FormSectionContainer>

              <FormSectionContainer>
                <h1>Administração e Governança</h1>
                <small>
                  Secretaria Estadual / Municipal - Principal Responsável Pelas
                  Politicas de Segurança
                  <TitleInfo info=" Preencha os dados do(a) secretário(a) municipal/estadual que lidera o órgão que será o principal responsável pela coordenação do processo de execução da operação de crédito e da implantação das soluções financiadas. Caso o usuário cadastrado na plataforma seja o(a) próprio(a) secretário(a), replique as informações constantes da seção acima." />
                </small>
                <Row>
                  {!!preProposal && (
                    <>
                      <Column
                        small="12"
                        medium="6"
                        large="3"
                        display={printing.toString()}
                      >
                        <Input
                          name="secretary_title"
                          schema={schema}
                          defaultValue={
                            !!preProposal.administration.length
                              ? preProposal.administration[0].secretary_title
                              : ''
                          }
                          placeholder="Digite o nome da Secretária"
                        />
                      </Column>
                      <Column
                        small="12"
                        medium="6"
                        large="3"
                        display={printing.toString()}
                      >
                        <Input
                          name="secretary_name"
                          schema={schema}
                          placeholder="Secretário(a) Atual"
                          defaultValue={
                            !!preProposal.administration.length
                              ? preProposal.administration[0].secretary_name
                              : ''
                          }
                        />
                      </Column>
                      <Column
                        small="12"
                        medium="6"
                        large="3"
                        display={printing.toString()}
                      >
                        <InputMask
                          name="phone"
                          schema={schema}
                          mask="(99)99999-9999"
                          maskChar={null}
                          placeholder="Digite o telefone"
                          defaultValue={
                            !!preProposal.administration.length
                              ? preProposal.administration[0].phone
                              : ''
                          }
                        />
                      </Column>
                      <Column
                        small="12"
                        medium="6"
                        large="3"
                        display={printing.toString()}
                      >
                        <Input
                          name="email"
                          schema={schema}
                          placeholder="Digite o email"
                          defaultValue={
                            !!preProposal.administration.length
                              ? preProposal.administration[0].email
                              : ''
                          }
                        />
                      </Column>
                    </>
                  )}
                  <Column small="12" medium="12" large="3" display={printing.toString()}>
                    <div className="fileButtonContainer">
                      <div className="downloadDiv">
                        <FileInput
                          name="organogram_file"
                          title="Upload de Organograma"
                          schema={schema}
                        />
                        {!!preProposal.organogram_file && (
                          <a href={preProposal.organogram_file} download>
                            download
                          </a>
                        )}
                      </div>
                      <div className="downloadDiv">
                        <FileInput
                          name="internal_regiment_file"
                          title="Upload de regimento interno"
                          schema={schema}
                        />
                        {!!preProposal.internal_regiment_file && (
                          <a href={preProposal.internal_regiment_file} download>
                            download
                          </a>
                        )}
                      </div>
                    </div>
                  </Column>
                  <PrintableDiv display={printing.toString()}>
                    {!!preProposal.administration.length && (
                      <>
                        <span>nome da Secretária</span>
                        <p>{preProposal.administration[0].secretary_title}</p>
                        <span>Secretário(a) Atual</span>
                        <p>{preProposal.administration[0].secretary_name}</p>
                        <span>telefone</span>
                        <p>{preProposal.administration[0].phone}</p>
                        <span>email</span>
                        <p>{preProposal.administration[0].email}</p>
                      </>
                    )}
                  </PrintableDiv>
                </Row>
              </FormSectionContainer>

              <FormSectionContainer>
                <small>
                  Secretaria(s) / Orgãos Envolvidos (Partes interessadas /
                  'Stakeholders')
                  <TitleInfo info="Campo em que devem ser apresentados os órgãos que deverão ser envolvidos direta ou indiretamente para a boa execução dos projetos / soluções ('partes interessadas'/'stakeholders')." />
                </small>
                <Row>
                  <Column small="12" medium="12" large="12" display={printing.toString()}>
                    <div className="input-container">
                      <Input
                        name="secretary_name_input"
                        placeholder="Digite o nome da Secretária"
                      />

                      <button type="button" onClick={() => addSecretaries()}>
                        <FaPlus color="#ffffff" />
                      </button>
                    </div>
                  </Column>
                </Row>
                <Row className="itens-row">
                  <Column small="12" medium="12" large="12">
                    <DataTable
                      columns={secretariesColumns}
                      data={involvedSecretaries}
                      noDataComponent=""
                      noHeader
                    />
                    {involvedSecretaries.length === 0 && (
                      <p className="noOptionsMessage">
                        Nenhuma Secretária Adicionada
                      </p>
                    )}
                  </Column>
                </Row>
              </FormSectionContainer>
              <FormSectionContainer>
                <h1>
                  Detalhamento da Governança
                  <TitleInfo
                    info="Apresentação dos principais órgãos e secretarias que estarão à frente dos projetos a serem contemplados pela operação de crédito (PROSEG Federativo). Na sequência, deve ser detalhada a forma pela qual eles se articulam entre si. Se houver necessidade de alguma parceria externa (órgãos do governo federal e/ou outro ente subnacional) especificar qual, qual a estrutura de governança necessária e a forma como será formalizada. 
                          Obs.: se, após a implantação do projeto, for necessária uma nova estrutura de governança para a sua correta operacionalização, detalhar: qual estrutura será criada, quais instituições estarão à frente, quais ações são necessárias para a estruturação da nova governança."
                  />
                </h1>
                <Row>
                  <Column small="12" medium="12" large="12" display={printing.toString()}>
                    <TextArea
                      name="governance_detail"
                      placeholder="Descrição da Governança"
                      schema={schema}
                      defaultValue={
                        !!preProposal.governance_detail
                          ? preProposal.governance_detail
                          : ''
                      }
                    />
                  </Column>
                  <PrintableDiv display={printing.toString()}>
                    {formRef.current?.getFieldValue('governance_detail')}
                  </PrintableDiv>
                </Row>
              </FormSectionContainer>
              <FormSectionContainer>
                <h1>
                  Alinhamento Estratégico
                  <TitleInfo info="Nesse campo deve-se inserir um texto (sintético) através do qual demonstre-se a forma como a contratação de operação de crédito junto ao PROSEG Federativo se alinha com i) a estratégia de segurança pública do estado/município; e (ii) com instrumentos de planejamento de curto, médio e longo prazos do estado/município. Considere demonstrar a previsão do projeto no Plano Plurianual e na Lei Orçamentária em vigor, se houver. Também poderão ser anexados, nesse campo, os documentos que sintetizem os principais instrumentos de planejamento de curto, médio e longo prazos da Segurança Pública e/ou da própria adminsitração  estadual / municipal que contenha orientações (metas, diretrizes, etc.) à área de segurança pública." />
                </h1>
                <Row>
                  <Column small="12" medium="12" large="12" display={printing.toString()}>
                    <TextArea
                      name="strategic_alignments"
                      schema={schema}
                      placeholder="Descrição da Estratégia"
                      defaultValue={
                        !!preProposal.strategic_alignments
                          ? preProposal.strategic_alignments
                          : ''
                      }
                    />
                  </Column>
                  <PrintableDiv display={printing.toString()}>
                    {formRef.current?.getFieldValue('strategic_alignments')}
                  </PrintableDiv>
                  <Column small="12" medium="12" large="3" display={printing.toString()}>
                    <div className="fileButtonContainer">
                      <div className="downloadDiv">
                        <FileInput
                          name="planning_instrument"
                          title="Upload de documento(Instrumento de Planejamento e/ou Orientação Estratégica)"
                          schema={schema}
                        />
                        {!!preProposal.planning_instrument && (
                          <a href={preProposal.planning_instrument} download>
                            download
                          </a>
                        )}
                      </div>
                    </div>
                  </Column>
                </Row>
              </FormSectionContainer>
              <FormSectionContainer>
                <h1>
                  Foco Territorial
                  <TitleInfo info=" No caso de governos estaduais, deve-se especificar, nesta seção, os municípios prioritários (principais destinatários dos entregáveis cadastrados) e suas respectivas populações, conforme estimativa mais recente disponível. No caso de Prefeituras Municipais, devem ser especificados os bairros e suas respectivas populações (estimativa, conforme dados mais recentes disponíveis)." />
                </h1>
                <Row>
                  <Column small="12" medium="12" large="12" display={printing.toString()}>
                    <div className="input-container">
                      <Input name="location" placeholder="Municipio/Bairro" />
                      <Input
                        name="population"
                        placeholder="População Estimada"
                      />
                      <button
                        type="button"
                        onClick={() => addTerritorialFocus()}
                      >
                        <FaPlus color="#ffffff" />
                      </button>
                    </div>
                  </Column>
                </Row>
                <Row className="itens-row">
                  <Column small="12" medium="12" large="12">
                    <DataTable
                      columns={territorialFocusColumns}
                      data={territorialFocus}
                      noDataComponent=""
                      noHeader
                    />
                    {territorialFocus.length === 0 && (
                      <p className="noOptionsMessage">
                        Nenhum Foco Territorial Adicionado
                      </p>
                    )}
                  </Column>
                </Row>
              </FormSectionContainer>
              <FormSectionContainer>
                <Row>
                  <Column small="12" medium="12" large="12">
                    <Title>Soluções</Title>
                    {!!preProposal && !!Object.keys(user).length  && (
                      <>
                        {preProposal.location_solutions?.map((solution, index) => (
                          
                          <SolutionsContainer key={`s-${index}-${solution.code}`}>
                            <Solution solution={solution} />
                          </SolutionsContainer>
                        
                        ))}
                        <TotalContainer>{`Estimativa Total: ${formatPrice(
                          total,
                        )}`}</TotalContainer>
                      </>
                    )}
                  </Column>
                </Row>
              </FormSectionContainer>
              <Row className="itens-row">
                <Column
                  small="12"
                  medium="12"
                  large="12"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  display={printing.toString()}
                >
                  <FormButton
                    display={printing.toString()}
                    onClick={() => Goback()}
                    type="button"
                    style={{ marginRight: '15px' }}
                  >
                    Editar
                  </FormButton>

                  <FormButton
                    display={printing.toString()}
                    onClick={() => printDocument()}
                    style={{ marginRight: '15px' }}
                    type="button"
                  >
                    Imprimir
                  </FormButton>

                  <FormButton display={printing.toString()} type="submit">
                    Confirmar
                  </FormButton>
                </Column>
              </Row>
            </Form>
            {showSuccess && (
              <SuccessModal
                loading={loadingSuccess}
                handleClose={() => setShowSuccess(false)}
                show={showSuccess}
                successText="Pré Proposta Finalizada"
              />
            )}
          </PrintableBodyWrapper>
        )}
      </DashboardLayout>
    </Container>
  );
};

export default FinalizePreProposal;
