import React, { useState, useEffect } from 'react';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';
import HowAccess from './components/HowAccess';
import { Container, MainContent } from './styles';
import Proseg from './components/Proseg';
import Estrutura from './components/Estrutura';
import imgProseg from '../../../assets/Arte8.png';
import Resultados from './components/Resultados';
import PublicoAlvo from './components/PublicoAlvo';
import MunicipioLista from './components/MunicipioLista';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


function SobrePrograma() {
  const [loading, setLoading] = useState(true);
  // const { code } = useParams();

  useEffect(() => {
    setLoading(false);
    // console.log(code);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={true}>
          <Container>
            <MainContent>
              <div className="imageWrapper">
                <div className="imageContent">
                  <div className="titleContent">O Programa</div>
                </div>
              </div>

              <div className="content">
                <div className="tab-container">
                  <Link
                    to="/programa/sobre-o-programa"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab1">O PROSEG Federativo</div>
                  </Link>

                  <Link
                    to="/programa/ficha-tecnica"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Ficha Técnica</div>
                  </Link>
                  <Link
                    to="/programa/perguntas-e-respostas"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="tab2">Perguntas Frequentes</div>
                  </Link>
                </div>
              </div>
              <div className="content">
                <Proseg />
              </div>
              <div className="grayWrapper">
                <div className="content">
                  <Estrutura />
                </div>
              </div>
              <div className="content">
                <div className="img-proseg-wrapper">
                  <img src={imgProseg} alt="" className="img-proseg" />
                </div>
              </div>
              <div className="grayWrapper">
                <div className="content">
                  <Resultados />
                </div>
              </div>
              <div className="content">
                <HowAccess />
              </div>
              <div className="grayWrapper">
                <div className="content">
                  <PublicoAlvo />
                </div>
              </div>

              <div className="content">
                <h1>Saiba mais sobre as condições de financiamento</h1>
                <p>
                  O PROSEG Federativo mobiliza uma linha de crédito de US$ 180
                  milhões, no total. O crédito do BID, de US$ 150 milhões, tem
                  prazo de amortização de 25 anos, período de carência de 5 anos
                  e meio, e taxa de juros baseada na{' '}
                  <a
                    href="https://www.iadb.org/en/idb-finance/libor-transition"
                    target="_blank"
                  >
                    LIBOR
                  </a>
                  . A esse montante, soma-se uma contrapartida local de US$ 30
                  milhões, por parte do BNDES. Para maiores informações sobre as
                  condições financeiras do BNDES, confira o portal do BNDES
                  Finem - Segurança Pública {''}
                  <a
                    href="https://www.bndes.gov.br/wps/portal/site/home/financiamento/produto/bndes-finem-seguranca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    portal do BNDES .{' '}
                  </a>
                </p>
              </div>
            </MainContent>
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default SobrePrograma;
