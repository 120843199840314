import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoader } from '../../hooks/loader';
import { parseISO, format } from 'date-fns';
import { useCreditOperation } from '../../hooks/creditOperation';
import { formatPrice } from '../../services/currencyFormatter';
import { MdDateRange } from 'react-icons/md';
import {
  FaMoneyBillAlt,
  FaUnlockAlt,
  FaLock,
  FaSearch,
  FaBook,
} from 'react-icons/fa';
import icon1 from '../../assets/icon_painel_1.png';
import icon2 from '../../assets/icon_painel_2.png';
import icon3 from '../../assets/icon_painel_3.png';
import DashboardLayout from '../../components/DashboardLayout';
import PercentageBar from '../../components/PercentageBar';
import TimePercentageBar from '../../components/TimePercentageBar';
import Button from '../../components/Button';

import { Container, ManagementGrid, Card, StatusOperation } from './styles';

const ManagementMonitoring = () => {
  const { push } = useHistory();
  const { activeLoader } = useLoader();
  const { creditOperation } = useCreditOperation();

  useEffect(() => {
    activeLoader(true);
    if (!!Object.keys(creditOperation).length && activeLoader(false));
  }, [creditOperation, activeLoader]);

  return (
    <Container>
      <DashboardLayout title="Gestão e Monitoramento">
        <ManagementGrid>
          {!!Object.keys(creditOperation).length ? (
            <>
              <Card className="card1">
                <header>
                  <h1>
                    {`${creditOperation.financing_entity}(${creditOperation.code})`}
                    <br />
                    {creditOperation.operation_title}
                  </h1>
                </header>
                <div className="content">
                  <div className="info-container">
                    <MdDateRange size={20} />
                    <span>
                      Data de Início:
                      <b>{` ${format(
                        parseISO(creditOperation.start_date),
                        'dd/MM/yyyy',
                      )}`}</b>
                    </span>
                  </div>

                  <div className="info-container">
                    <MdDateRange size={20} />
                    <span>
                      Data de Conclusão:
                      <b className="blue">{` ${format(
                        parseISO(creditOperation.end_date),
                        'dd/MM/yyyy',
                      )}`}</b>
                    </span>
                  </div>

                  <div className="info-container">
                    <FaMoneyBillAlt size={20} />
                    <span>
                      Valor Global:
                      <b className="green">{` ${formatPrice(
                        creditOperation.global_value.value,
                      )}`}</b>
                    </span>
                  </div>
                  <>
                    <div className="bar-container">
                      <PercentageBar
                        percentage={creditOperation.execution.physics}
                        title="Execução Fisica(%)"
                        color="yellow"
                      />
                    </div>

                    <div className="bar-container">
                      <PercentageBar
                        percentage={creditOperation.execution.finance}
                        title="Execução Financeira(%)"
                        color="green"
                      />
                    </div>

                    <div className="bar-container">
                      <PercentageBar
                        percentage={(creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100) > 100 ? 100 : (creditOperation.execution.time_month.time / creditOperation.execution.time_month.total * 100)}
                        color="blue"
                        title="Tempo"
                      />
                    </div>
                  </>
                  <StatusOperation
                    structuring={creditOperation.status === 'structuring'}
                  >
                    {creditOperation.status === 'structuring' &&
                      !creditOperation.is_delete && (
                        <>
                          <FaUnlockAlt />
                          Projeto em Estruturação
                        </>
                      )}
                    {creditOperation.status === 'execution' &&
                      !creditOperation.is_delete && (
                        <>
                          <FaLock />
                          Projeto em Execução
                        </>
                      )}
                    {creditOperation.status === 'done' &&
                      !creditOperation.is_delete && (
                        <>
                          <FaLock />
                          Projeto em Concluído
                        </>
                      )}
                    {creditOperation.is_delete && (
                      <>
                        <FaLock />
                        Projeto em Inativo
                      </>
                    )}
                  </StatusOperation>

                  <Button
                    color="dark-blue2"
                    onClick={() => push('/projects/monitoramento-credito')}
                  >
                    <FaSearch /> Ver Operações de Crédito
                  </Button>
                </div>
              </Card>

              <Card className="card2">
                <div className="content">
                  <h1>
                    Olá, bem vindo ao módulo de Gestão e Monitoramento do PROSEG
                    Federativo!
                  </h1>
                  <p>
                    Esta etapa é reservada para estados e municípios que
                    concluíram o processo de contratação de novas operações de
                    crédito junto ao BID/BNDES no âmbito do PROSEG Federativo.
                  </p>
                  <br />
                  <p>
                    Nesta seção você pode acompanhar a execução da operação de
                    crédito por meio do gerenciamento e monitoramento intensivos
                    dos projetos desenvolvidos através dos empréstimos
                    contratados junto às entidades financiadoras do programa.
                  </p>
                  <br />
                  <p>
                    Clique abaixo e acesse o Painel de Monitoramento dos
                    Projetos e o Painel de Monitoramento dos Impactos.
                  </p>
                  <Button color="gray3">
                    <FaBook /> Manual do Usuário
                  </Button>
                </div>
              </Card>

              <Card
                className="card3 card"
                onClick={() => push('/projects/operacoes-credito')}
              >
                <div className="content">
                  <img src={icon1} alt="icone 1" />
                  <span>Operações de Crédito do PROSEG Federativo</span>
                  <p>
                    Visão de conjunto das operações de crédito do PROSEG
                    Federativo contratadas por outros entes federativos
                  </p>
                </div>
              </Card>

              <Card
                className="card4 card"
                onClick={() => push('/projects/monitoramento-impactos')}
              >
                <div className="content">
                  <img src={icon2} alt="icone 2" />
                  <span>Painel de Monitoramento dos Impactos</span>
                  <p>
                    Trajetória recente e tendências extrapolativas para os
                    indicadores de impacto e visão comparativa com os demais
                    entes
                  </p>
                </div>
              </Card>

              <Card
                className="card5 card"
                onClick={() => push('/projects/nucleo-conhecimento')}
              >
                <div className="content">
                  <img src={icon3} alt="icone 3" />
                  <span>Núcleo de Conhecimento</span>
                  <p>
                    Repositório de documentos, Plataforma de Ensino e Banco de
                    Dúvidas
                  </p>
                </div>
              </Card>
            </>
          ) : (
            <p>Nenhuma operação de crédito vinculada a você</p>
          )}
        </ManagementGrid>
      </DashboardLayout>
    </Container>
  );
};

export default ManagementMonitoring;
