/* eslint-disable */
import React, { useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { FaLock, FaEnvelope, FaSignInAlt } from 'react-icons/fa';
import Modal from '../Modal';
import { Form } from '@unform/web';
import { useAuth } from '../../hooks/auth';
import Input from '../Input';
import ModalLoader from '../ModalLoader';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import Logo from '../../assets/logo_plataforma_novo.png';
import Bid from '../../assets/logo-bid-no-background.png';
import { Container } from './styles';

const schema = Yup.object().shape({
  mail: Yup.string()
    .email('digite um email válido')
    .required('email é obrigatório'),
  password: Yup.string().required('Senha é obrigatória'),
});

function LoginModal({ show, handleClose }) {
  const formRef = useRef(null);
  const { changeForgotModal, changeLoginModal } =
    useAuth();
  const [loading, setLoading] = useState(false);

  const openForgotModal = useCallback(() => {
    changeLoginModal(false);
    changeForgotModal(true);
  }, [changeForgotModal, changeLoginModal]);

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      await api.post('accounts/login/', data);

      setLoading(false);

      window.location = '/projects/';
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setLoading(false);
      console.error('err', err);
    }
  }, []);

  return (
    <Container>
      <Modal
        title="Login"
        success={false}
        successText=""
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <div className="loginContainer">
            <div className="formContainer">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Entrar</h1>
                <Input
                  id="email"
                  name="mail"
                  icon={FaEnvelope}
                  className="input"
                  placeholder="Email"
                />
                <Input
                  id="password"
                  name="password"
                  icon={FaLock}
                  className="input"
                  type="password"
                  placeholder="Senha"
                />
                <div className="submitContainer">
                  <button
                    type="button"
                    className="forgotButton"
                    onClick={openForgotModal}
                  >
                    Esqueci minha senha
                  </button>
                  <button type="submit" className="submit">
                    <FaSignInAlt />
                    Enviar
                  </button>
                </div>
              </Form>
            </div>
            <div className="infoContainer">
              <div className="logo1">
                <img src={Logo} alt="" />
              </div>
              <div className="text">
                <span>Acesso às linhas de financiamento</span>
              </div>
              <div className="logo2">
                <img src={Bid} alt="" />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Container>
  );
}

export default LoginModal;
